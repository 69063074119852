<template>
  <validation-observer ref="simpleRules">
    <div>
      <b-card>
        <div class="d-lg-flex justify-content-between align-items-center">
          <h3 class="font-weight-bolder">
            {{ $t('bankAccount') }}
          </h3>
          <div class="income-order-search order-search">
            <div class="anan-input search-input">
              <div class="anan-input__inner anan-input__inner--normal">
                <input
                  v-model="filter"
                  type="text"
                  :placeholder="$t('search')"
                  class="anan-input__input"
                >
                <div class="anan-input__suffix">
                  <i class="anan-input__clear-btn anan-icon" />
                  <i class="anan-input__suffix-icon anan-icon">
                    <i class="fal fa-search" />
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          class="anan-button--xl-large anan-button anan-button--primary anan-button--normal mt-1"
          @click="showAddNew()"
        >
          <span> {{ $t('addBankAccount') }} </span>
        </button>
        <b-overlay
          :show="showOver"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            show-empty
          >
            <template #empty>
              <div class="text-center">
                <img
                  src="@/assets/images/anan-img/empty/empty.png"
                  alt="empty"
                  width="180px"
                >
                <p>No Data</p>
              </div>
            </template>

            <template #cell(number)="data">
              BANK-{{ data.item.id }}
            </template>
            <!--
          <template #cell(accNumber)="data">
            {{ data.item.accNumber ? formatBankAccount(data.item.accNumber) : '-' }}
          </template> -->

            <template #cell(active)="data">
              <b-form-checkbox
                v-model="data.item.active"
                name="check-button"
                :value="1"
                :unchecked-value="0"
                switch
                @change="CheckUpdate(data.item.active, 'active',data.item.id)"
              />
            </template>

            <template #cell(Kbiz)="data">
              <b-button
                v-if="data.item.bank_id === 2"
                variant="outline-dark"
                size="sm"
                @click="ChangeAuto(data.item)"
              >
                <span :class="`bullet bullet-sm bullet-${data.item.id === auto_id ? 'success' : 'danger'}`" />
                {{ $t('key-303') }}
              </b-button>
            </template>

            <template #cell(actions)="data">
              <feather-icon
                icon="Edit3Icon"
                class="mr-1 text-primary cursor-pointer"
                @click="showAddNew(data.item)"
              />

              <feather-icon
                class="text-danger cursor-pointer"
                icon="TrashIcon"
                @click="DeleteData(data.item)"
              />
            </template>
          </b-table>
        </b-overlay>
        <div class="demo-spacing-0 d-flex justify-content-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getBank()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card>
    </div>

    <b-modal
      id="modal-add-new"
      :title="$t('addBankAccount')"
      :ok-title="$t('save')"
      :cancel-title="$t('cancel')"
      @ok="submitAddNew()"
    >
      <h4 class="font-weight-bolder">
        {{ $t('productTypeInfo') }}
      </h4>
      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="Bank"
          rules="required"
        >
          <label for="customerCode">{{ $t('bank') }} : <span class="text-danger">*</span></label>
          <v-select
            v-model="bank_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :state="errors.length > 0 ? false:null"
            label="title"
            :options="itemBank"
            :reduce="ele => ele.id"
            @input="toggleCollapse"
          >
            <template #option="{ name, path_photo }">
              <img
                :src="`/bankIcon/${path_photo}`"
                :alt="name"
                height="30"
                class="mr-50 rounded-sm"
              >
              <span>{{ name }}</span>
            </template>

            <template #selected-option="{ name, path_photo }">
              <img
                :src="`/bankIcon/${path_photo}`"
                :alt="name"
                height="30"
                class="mr-50"
              >
              <span>{{ name }}</span>
            </template>
          </v-select>
          <span class="text-danger">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>

      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="Bank Name"
          rules="required"
        >
          <label for="bank-name">{{ $t('accountName') }} : <span class="text-danger">*</span></label>
          <b-form-input
            id="bank-name"
            v-model="accName"
            :state="errors.length > 0 ? false:null"
            type="text"
            :placeholder="$t('accountName')"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>

      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="Bank Number"
          rules="required"
        >
          <label for="acc-number">{{ $t('accountNumber') }} : <span class="text-danger">*</span></label>
          <b-form-input
            id="acc-number"
            v-model="accNumber"
            :state="errors.length > 0 ? false:null"
            type="text"
            :placeholder="$t('accountNumber')"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>

      <b-form-group>
        <label for="branch">{{ $t('branch') }} : </label>
        <b-form-input
          id="branch2"
          v-model="branch"
          type="text"
          :placeholder="$t('branch')"
        />
      </b-form-group>

      <b-collapse
        v-model="showCollapse"
        class="mt-2"
      >
        <b-form-group>
          <label for="branch">{{ $t('username') }} K-BIZ : </label>
          <b-form-input
            id="UKBIZ"
            v-model="kbiz_username"
            type="text"
            :placeholder="$t('username')+ 'K-BIZ'"
          />
        </b-form-group>

        <b-form-group>
          <label for="branch">{{ $t('password') }} K-BIZ : </label>
          <b-form-input
            id="PKBIZ"
            v-model="kbiz_password"
            type="text"
            :placeholder="$t('password')+ 'K-BIZ'"
          />
        </b-form-group>

      </b-collapse>

      <b-form-group>
        <label for="type">{{ $t('type') }} : <span class="text-danger">*</span></label>
        <b-form-radio
          v-model="bank_type"
          name="some-radios"
          :value="1"
          :unchecked-value="0"
          class="mb-50"
        >
          {{ $t('bank') }}
        </b-form-radio>

        <b-form-radio
          v-model="bank_type"
          name="some-radios"
          :value="2"
          :unchecked-value="0"
        >
          Alipay
        </b-form-radio>
      </b-form-group>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BFormRadio, BTable, BPagination, BFormCheckbox, BCard, BOverlay, BCollapse,
  // BLink,
  BModal, BFormInput, BFormGroup, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  components: {
    BFormRadio,
    BPagination,
    BCard,
    BTable,
    // BLink,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    vSelect,
    BFormGroup,
    BOverlay,
    BCollapse,
    BButton,
  },

  data() {
    return {
      showCollapse: false,
      currentPage: 1,
      perPage: 10,
      bankAccountNumber: '1271085105',
      required,
      type: 'bank',
      search: '',
      isActive: 'all',
      totalRows: 0,
      filter: null,
      items: [],
      itemBank: [
        { name: 'ธนาคารกสิกรไทย', path_photo: '/bankIcon/KBANK.png' },
        { name: 'ธนาคารไทยพานิชญ์', path_photo: '/bankIcon/scb.png' },
        { name: 'ธนาคารกรุงศรีอยุธยา', path_photo: '/bankIcon/BAY.png' },
        { name: 'ธนาคารกรุงไทย', path_photo: '/bankIcon/KTB.png' }],
      productlist: [
        { text: 'รถ', value: 1 },
        { text: 'เรือ', value: 2 },
      ],
      id: null,
      active: 0,
      accName: '',
      accNumber: '',
      branch: '',
      bank_type: 1,
      showOver: false,
      kbiz_username: null,
      kbiz_password: null,
      auto_id: null,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'number', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'bankname', label: this.$t('number'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center',
        },
        {
          key: 'accNumber', label: this.$t('accountNumber'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'accName', label: this.$t('accountName'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'branch', label: this.$t('branch'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-right',
        },
        {
          key: 'Kbiz', label: `${this.$t('status')}KBIZ`, sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'active', label: this.$t('active'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'actions', label: this.$t('actions'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },

    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.perPage
      const endIndex = startIndex + this.perPage
      return this.items.slice(startIndex, endIndex)
    },
  },
  mounted() {
    this.getBank()
    this.getSelect()
  },
  methods: {
    toggleCollapse() {
      if (this.bank_id === 2) { // 2 = ธนาคารกสิกรไทย
        this.showCollapse = true
      } else {
        this.showCollapse = false
      }
    },
    async getBank(type) {
      this.showOver = true
      // this.isActive = type
      try {
        const params = {
          page: this.currentPage,
          perPage: this.perPage,
          type,
        }
        const { data: res } = await this.$http.get('/bankList/bank_show', { params })
        this.items = res.list.data
        this.totalRows = res.list.total
        this.auto_id = res.auto_id
        this.showOver = false
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    async getSelect() {
      try {
        const { data: res } = await this.$http.get('/bank')
        this.itemBank = res
      } catch (e) {
        console.log(e)
      }
    },
    CheckUpdate(data, fields, ID) {
      console.log(data, fields, ID)
      if (data || data === 0) {
        const obj = {
          data,
          fields,
          ID,
        }
        this.$http.post('/bankList/bank_update_filed', obj)
      }
    },
    submitAddNew() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$bvModal.hide('modal-add-new')
        }
      })
      const obj = {
        id: this.id,
        bank_id: this.bank_id, // ธนาคาร
        accName: this.accName, // ชื่อบัญชี
        accNumber: this.accNumber, // เลขบัญชี
        branch: this.branch, // ชื่อสาขา
        bank_type: this.bank_type, // ประเภท
        kbiz_username: this.kbiz_username, // ชื่อผู้ใช้งาน KBIZ
        kbiz_password: this.kbiz_password, // รหัสผ่าน KBIZ
      }
      this.$http
        .post('/bankList/bank_update', obj)
        .then(() => {
          this.getBank(this.isActive)
          this.$bvToast.toast('บันทึกข้อมูลสำเร็จ', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
        })
        .catch(error => {
          console.log(error)
          // this.SwalError(error.response.data.message)
        })
    },
    showAddNew(item) {
      this.$bvModal.show('modal-add-new')
      if (item) {
        this.id = item.id
        this.bank_id = item.bank_id
        this.accName = item.accName
        this.accNumber = item.accNumber
        this.branch = item.branch
        this.bank_type = item.bank_type
        this.active = item.active
        this.kbiz_username = item.kbiz_username
        this.kbiz_password = item.kbiz_password
        if (this.bank_id === 2) { // 2 = ธนาคารกสิกรไทย
          this.showCollapse = true
        } else {
          this.showCollapse = false
        }
      } else {
        this.id = null
        this.bank_id = null
        this.accName = null
        this.accNumber = null
        this.branch = null
        this.bank_type = null
        this.active = null
        this.kbiz_username = null
        this.kbiz_password = null
      }
    },
    EditRole(data) {
      this.$router.push({ name: 'admin-role-edit', params: { id: data.id } })
    },
    DeleteData(data) {
      this.$bvModal.msgBoxConfirm(this.$t('key-281'), {
        title: this.$t('key-217'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const params = {
              id: data.id,
            }
            this.$http.post('/bankList/bank_delete', params).then(() => {
              this.getBank(this.isActive)
              this.$bvToast.toast(this.$t('key-282'), {
                title: this.$t('key-280'),
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    ChangeAuto(item) {
      this.$bvModal.msgBoxConfirm(`${this.$t('key-304')} ${item.accNumber}`, {
        title: this.$t('key-305'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: this.$t('key-13'),
        cancelTitle: this.$t('cancel'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const params = {
              auto_id: item.id,
            }
            this.$http.post('/bankList/update_auto', params).then(res => {
              const { data } = res
              this.items = data.list
              this.totalRows = this.items.length
              this.auto_id = data.auto_id
              this.$bvToast.toast(this.$t('key-306'), {
                title: this.$t('key-280'),
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    formatBankAccount(value) {
      console.log(value)
      const formattedNumber = value.replace(/(\d{3})(\d{1})(\d{5})(\d{1})/, '$1-$2-$3-$4')
      return formattedNumber
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
